import React from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "../../shared/routes";
import ConfirmApp from "./confirmApp/confirmApp";
import { AlertContainer } from "@salesforce/design-system-react";
import IE11Alert from "./ie11Alert";

import * as Bowser from "bowser";

const parser = Bowser.getParser(window.navigator.userAgent);

/**
 * App router screen
 * @returns {any}
 * @constructor
 */
export default function App(): any {
  const isIE11 = parser.satisfies({ desktop: { ie: "<=11" } });
  if (isIE11) {
    return (
      <AlertContainer>
        <IE11Alert />
      </AlertContainer>
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path={routes.confirmApp} component={ConfirmApp} />
      </Switch>
    </Router>
  );
}
