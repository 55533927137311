import { LocalizationServiceInst } from "../../../services/localizationService";
import {
  BaseConfirmEventResponse,
  ConfirmAppResponse,
  ConfirmStatus,
  EventRequestResponse,
  ViewRequestResponse,
} from "../../../constants/iqResponses";
import { ConfirmAppScreen, ConfirmAppState } from "../confirmApp";

/**
 * Get a formatted available time string based on the start time
 * @param {ConfirmAppResponse} response
 * @param {number} startTime
 * @returns {string | undefined}
 */
export function getAvailableTimeFormatted(
  response: ConfirmAppResponse | undefined,
  startTime: string | undefined
): string | undefined {
  const {
    availableTimes,
    availableTimesFormatted,
  } = response?.result as ViewRequestResponse;
  const availableTimeIndex = availableTimes?.indexOf(Number(startTime));
  return availableTimesFormatted?.[availableTimeIndex as number];
}

/**
 * Compute the confirm app screen to display
 * @param {ConfirmAppResponse} response
 * @param {boolean} isViewCalendarMode
 * @param {boolean} displayConfirmTimePrompt
 * @returns {ConfirmAppScreen}
 */
export function computeConfirmAppScreen(
  response: ConfirmAppResponse,
  isViewCalendarMode = false,
  displayConfirmTimePrompt = false,
  startTime: string | undefined
): ConfirmAppScreen {
  if (response?.result) {
    const result = response.result;
    // Canceled response result is a string ("success")
    if (typeof result === "string" && result === "success") {
      return ConfirmAppScreen.CANCELED;
    } else if (typeof result === "object") {
      // If there's a scheduled event then the event is already confirmed
      if (
        result.scheduledEvent &&
        (result.status === ConfirmStatus.SUCCESS ||
          ConfirmStatus.ALREADY_CONFIRMED)
      ) {
        return ConfirmAppScreen.CONFIRMED;
      } else if (
        !result.scheduledEvent &&
        result.status === ConfirmStatus.SUCCESS
      ) {
        if (isViewCalendarMode) {
          return ConfirmAppScreen.VIEW_CALENDAR;
        }

        if (displayConfirmTimePrompt) {
          const hasAvailableTimeFormatted = !!getAvailableTimeFormatted(
            response,
            startTime
          );
          return hasAvailableTimeFormatted
            ? ConfirmAppScreen.CONFIRM_TIME_PROMPT
            : ConfirmAppScreen.START_TIME_UNAVAILABLE;
        }

        return ConfirmAppScreen.VIEW_ALL;
      } else if (
        !result.scheduledEvent &&
        result.status === ConfirmStatus.MULTIPLE_RECIPIENTS
      ) {
        return ConfirmAppScreen.MULTIPLE_RECIPIENTS;
      } else if (
        !result.scheduledEvent &&
        result.status === ConfirmStatus.START_TIME_UNAVAILABLE
      ) {
        return ConfirmAppScreen.START_TIME_UNAVAILABLE;
      }
    }
  }
  return ConfirmAppScreen.ERROR;
}

/**
 * Compute the status based on the response state (if present)
 * @param {ConfirmAppState} state
 * @returns {ConfirmStatus}
 */
export function computeStatus(state: ConfirmAppState): ConfirmStatus {
  if (state.response?.result) {
    if (typeof state.response.result === "string") {
      if (state.response.result === "success") {
        return ConfirmStatus.SUCCESS;
      } else {
        return ConfirmStatus.REQUEST_INVALID;
      }
    } else {
      if (
        state.response.result.status === ConfirmStatus.SUCCESS &&
        !(state.response.result as ViewRequestResponse).availableTimes?.length
      ) {
        return ConfirmStatus.START_TIME_UNAVAILABLE;
      }
      return state.response.result.status;
    }
  } else {
    return ConfirmStatus.OTHER_ERROR;
  }
}

/**
 * Compute the modal header label based on the current state
 * @param {ConfirmAppState} state
 * @returns {string}
 */
export function computeModalHeaderLabel(state: ConfirmAppState): string {
  switch (state.screen) {
    case ConfirmAppScreen.CANCELED:
      return LocalizationServiceInst.getLabel("reservation_canceled") as string;
    case ConfirmAppScreen.ERROR:
      return LocalizationServiceInst.getLabel("request_invalid") as string;
    case ConfirmAppScreen.CONFIRMED:
    case ConfirmAppScreen.MULTIPLE_RECIPIENTS:
    case ConfirmAppScreen.START_TIME_UNAVAILABLE:
    case ConfirmAppScreen.VIEW_ALL:
    case ConfirmAppScreen.CONFIRM_TIME_PROMPT:
      const title = (state.response?.result as BaseConfirmEventResponse)
        .eventRequest?.eventTitle;
      if (title) {
        return LocalizationServiceInst.getLabel("event_title", [
          title,
        ]) as string;
      } else {
        return LocalizationServiceInst.getLabel("window_title") as string;
      }
    case ConfirmAppScreen.VIEW_CALENDAR:
      return (
        (state.response?.result as BaseConfirmEventResponse).eventRequest
          ?.eventTitle || ""
      );
    default:
      return state.loading
        ? (LocalizationServiceInst.getLabel("checking_availability") as string)
        : (LocalizationServiceInst.getLabel("request_invalid") as string);
  }
}

/**
 * Compute the "All times in" label based on the state
 * @param {ConfirmAppState} state
 * @returns {string | undefined}
 */
export function computeAllTimesLabel(
  state: ConfirmAppState,
  isViewCalendarMode: boolean
): string | undefined {
  const timeZoneName = (state.response?.result as BaseConfirmEventResponse)
    ?.eventRequest?.timeZoneName;
  const offsetInMin = (state.response?.result as BaseConfirmEventResponse)
    ?.eventRequest?.offsetInMin;
  const isOffsetInMinNumber = typeof offsetInMin === "number";
  switch (state.screen) {
    case ConfirmAppScreen.START_TIME_UNAVAILABLE:
      if (isViewCalendarMode) {
        return undefined;
      }

      if (timeZoneName && isOffsetInMinNumber) {
        return LocalizationServiceInst.getLabel("all_times_in", [timeZoneName]);
      } else {
        return LocalizationServiceInst.getLabel("your_local_time");
      }
    case ConfirmAppScreen.VIEW_ALL:
    case ConfirmAppScreen.VIEW_CALENDAR:
    case ConfirmAppScreen.CONFIRM_TIME_PROMPT:
      if (timeZoneName && isOffsetInMinNumber) {
        return LocalizationServiceInst.getLabel("all_times_in", [timeZoneName]);
      } else {
        return LocalizationServiceInst.getLabel("your_local_time");
      }
    default:
      return undefined;
  }
}

export function localizeApp(response: ConfirmAppResponse): void {
  if (
    response?.result &&
    typeof response.result !== "string" &&
    response.result.labelsMap
  ) {
    LocalizationServiceInst.storeLocalizedLabels(response.result.labelsMap);
    document.title = LocalizationServiceInst.getLabel("window_title") as string;
    document.dir = LocalizationServiceInst.isRtlLanguage(
      (response.result.eventRequest as EventRequestResponse)?.locale
    )
      ? "rtl"
      : "ltr";
  }
}
