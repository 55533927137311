import React from "react";
import { LocalizationServiceInst } from "../../services/localizationService";

/**
 * Canceled event screen
 * @returns {any}
 * @constructor
 */
function CanceledEvent(props: { isViewCalendarMode: boolean }): any {
  const { isViewCalendarMode } = props;
  return (
    <div className="slds-p-around_large">
      <p>
        {LocalizationServiceInst.getLabel(
          isViewCalendarMode
            ? "reserve_another_time_view_cal"
            : "reserve_another_time"
        )}
      </p>
    </div>
  );
}

export default CanceledEvent;
