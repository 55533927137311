import React, { ReactNode } from "react";
import {
  cancelEvent,
  confirmEvent,
  viewCalendarWeek,
  viewEvents,
} from "../../services/confirmAppService";
import UrlUtils from "../../utils/urlUtils";
import {
  ConfirmAppResponse,
  EventRequestResponse,
  ScheduledEvent,
  BaseConfirmEventResponse,
  ViewRequestResponse,
  ViewCalendarResponse,
} from "../../constants/iqResponses";
import { Button, Modal, Spinner } from "@salesforce/design-system-react";
import { isAllowedUrl } from "../../../shared/iqUrls";
import { LocalizationServiceInst } from "../../services/localizationService";
import ConfirmAppError from "./confirmAppError";
import ViewEvents from "./viewEvents";
import ConfirmedEvent from "./confirmedEvent";
import CanceledEvent from "./canceledEvent";
import "./confirmApp.css";
import {
  computeAllTimesLabel,
  computeModalHeaderLabel,
  computeConfirmAppScreen,
  computeStatus,
  localizeApp,
  getAvailableTimeFormatted,
} from "./helpers/confirmAppHelper";
import ViewCalendarWeek from "./viewCalendarWeek";
import { ViewCalendarWeekHeader } from "./viewCalendarWeekHeader";

/**
 * Interface for the confirm app state
 */
export interface ConfirmAppState {
  response: ConfirmAppResponse | undefined;
  modalHeading: string;
  screen: ConfirmAppScreen | undefined;
  loading: boolean;
}

/**
 * Possible confirm app screens
 */
export enum ConfirmAppScreen {
  CANCELED,
  CONFIRMED,
  ERROR,
  MULTIPLE_RECIPIENTS,
  START_TIME_UNAVAILABLE,
  VIEW_ALL,
  VIEW_CALENDAR,
  CONFIRM_TIME_PROMPT,
}

/**
 * Main confirm app component
 */
class ConfirmApp extends React.Component<any, ConfirmAppState> {
  private readonly isViewMode: false | boolean;
  private readonly isViewCalendarMode: false | boolean;
  private readonly currentApiHost: string;
  private readonly encryptedId: string;
  private startTime: string;

  constructor(props: void) {
    super(props);
    this.state = {
      response: undefined,
      modalHeading: LocalizationServiceInst.getLabel(
        "checking_availability"
      ) as string,
      screen: undefined,
      loading: true,
    };

    const params = UrlUtils.getQueryParams();
    const viewParam = params.get("view") as string;
    const viewCalendarParam = params.get("viewCal") as string;

    this.isViewMode = !!viewParam && viewParam !== "false";
    this.isViewCalendarMode =
      !!viewCalendarParam && viewCalendarParam !== "false";
    this.currentApiHost = params.get("currentApiHost") as string;
    this.encryptedId = params.get("t") as string;
    this.startTime = params.get("s") as string;
  }

  /**
   * Handle a successful server response
   * @param {ConfirmAppResponse} response
   * @param {boolean} displayConfirmTimePrompt
   * @private
   */
  private handleSuccess(
    response: ConfirmAppResponse,
    displayConfirmTimePrompt = false
  ): void {
    localizeApp(response);
    this.setState({
      response: response,
      screen: computeConfirmAppScreen(
        response,
        this.isViewCalendarMode,
        displayConfirmTimePrompt,
        this.startTime
      ),
      loading: false,
    });
  }

  /**
   * Handle an error response
   * @private
   */
  private handleError(): void {
    this.setState({
      screen: ConfirmAppScreen.ERROR,
      loading: false,
    });
  }

  /**
   * Submit a view event request
   * @param {boolean} displayConfirmTimePrompt
   * @private
   */
  private view(displayConfirmTimePrompt = false): void {
    this.setState({ loading: true });
    viewEvents(this.encryptedId, this.currentApiHost)
      .then((response) => {
        this.handleSuccess(response, displayConfirmTimePrompt);
      })
      .catch(this.handleError.bind(this));
  }

  /**
   * Submit a view calendar request
   * @param {string} isoDate
   * @private
   */
  private viewCalendarWeek(isoDate?: string): void {
    this.setState({ loading: true });
    viewCalendarWeek(this.encryptedId, isoDate, this.currentApiHost)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleError.bind(this));
  }

  /**
   * Submit a confirm event request
   * @param {string} startTime
   * @param {boolean} isPrimaryAttendee
   * @private
   */
  private confirm(startTime: string, isPrimaryAttendee = false): void {
    this.startTime = startTime;
    this.setState({ loading: true });
    confirmEvent(
      this.encryptedId,
      this.startTime,
      isPrimaryAttendee,
      this.currentApiHost
    )
      .then(this.handleSuccess.bind(this))
      .catch(this.handleError.bind(this));
  }

  /**
   * Submit a cancel event request
   * @private
   */
  private cancel(): void {
    this.setState({ loading: true });
    cancelEvent(this.encryptedId, this.currentApiHost)
      .then(this.handleSuccess.bind(this))
      .catch(this.handleError.bind(this));
  }

  componentDidMount(): void {
    // If currentApiHost param isn't allowed, display an error
    if (!isAllowedUrl(this.currentApiHost)) {
      this.setState({
        screen: ConfirmAppScreen.ERROR,
        loading: false,
      });
      return;
    }

    // If we're in "View all times" mode
    if (this.isViewMode) {
      this.view();
    } else if (this.isViewCalendarMode) {
      // If we're in "View Calendar" mode, show a week-based calendar
      this.viewCalendarWeek();
    } else {
      // Trigger a view request with displayConfirmTimePrompt=true if confirming via a direct timeslot selection from the email
      this.view(true);
    }
  }

  /**
   * Render view all events screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderViewAllEventsScreen(): ReactNode {
    const { availableTimes, availableTimesFormatted } = this.state.response
      ?.result as ViewRequestResponse;
    const { duration, timeZoneName, offsetInMin } = (this.state.response
      ?.result as BaseConfirmEventResponse)
      .eventRequest as EventRequestResponse;
    if (availableTimes?.length) {
      return (
        <ViewEvents
          availableTimes={availableTimes as number[]}
          availableTimesFormatted={availableTimesFormatted as string[]}
          confirmEvent={this.confirm.bind(this)}
          duration={duration}
          message={
            LocalizationServiceInst.getLabel("select_meeting_time") as string
          }
          offsetInMin={offsetInMin}
          timeZoneName={timeZoneName}
        />
      );
    } else {
      return this.renderErrorScreen();
    }
  }

  /**
   * Render view calendar week screen
   * @private
   */
  private renderViewCalendarWeekScreen(): ReactNode {
    const { availableTimes, availableTimesFormatted } = this.state.response
      ?.result as ViewCalendarResponse;
    const { linkAvailabilityStart, linkAvailabilityEnd, duration } = (this.state
      .response?.result as BaseConfirmEventResponse)
      .eventRequest as EventRequestResponse;
    if (
      availableTimes?.length &&
      availableTimesFormatted?.length &&
      linkAvailabilityStart &&
      linkAvailabilityEnd
    ) {
      return (
        <ViewCalendarWeek
          availableTimes={availableTimes}
          availableTimesFormatted={availableTimesFormatted}
          confirmEvent={this.confirm.bind(this)}
          duration={duration}
          loading={this.state.loading}
        />
      );
    } else {
      return this.renderErrorScreen();
    }
  }

  /**
   * Render start times unavailable screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderStartTimesUnavailableScreen(): ReactNode {
    const { availableTimes, availableTimesFormatted } = this.state.response
      ?.result as ViewRequestResponse;
    const { duration, timeZoneName, offsetInMin } = (this.state.response
      ?.result as BaseConfirmEventResponse)
      .eventRequest as EventRequestResponse;
    if (availableTimes && availableTimes.length) {
      return (
        <ViewEvents
          availableTimes={availableTimes}
          availableTimesFormatted={availableTimesFormatted as string[]}
          confirmEvent={this.confirm.bind(this)}
          duration={duration}
          message={
            LocalizationServiceInst.getLabel("select_another_time") as string
          }
          offsetInMin={offsetInMin}
          timeZoneName={timeZoneName}
          warning={
            LocalizationServiceInst.getLabel("time_recently_booked") as string
          }
        />
      );
    } else {
      return this.renderErrorScreen();
    }
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Render multiple recipients screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderMultipleRecipientScreen(): ReactNode {
    return (
      <div className="slds-p-around_large">
        {
          LocalizationServiceInst.getLabel(
            "multiple_recipients_message"
          ) as string
        }
      </div>
    );
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Render confirm time prompt screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderConfirmTimePromptScreen(): ReactNode {
    const availableTimeFormatted = getAvailableTimeFormatted(
      this.state.response,
      this.startTime
    );
    return (
      <div className="slds-p-around_large">
        {
          LocalizationServiceInst.getLabel("confirm_time_prompt", [
            availableTimeFormatted as string,
          ]) as string
        }
      </div>
    );
  }

  /**
   * Render confirmed event screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderConfirmedEventScreen(): ReactNode {
    const { status } = this.state.response?.result as BaseConfirmEventResponse;
    const { start, startEndTimeFormatted, location } = (this.state.response
      ?.result as BaseConfirmEventResponse).scheduledEvent as ScheduledEvent;
    const { duration, timeZoneName, offsetInMin } = (this.state.response
      ?.result as BaseConfirmEventResponse)
      .eventRequest as EventRequestResponse;
    return (
      <ConfirmedEvent
        duration={duration}
        location={location}
        offsetInMin={offsetInMin}
        start={start}
        startEndTimeFormatted={startEndTimeFormatted}
        status={status}
        timeZoneName={timeZoneName}
      />
    );
  }

  /**
   * Render error screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderErrorScreen(): ReactNode {
    return (
      <ConfirmAppError
        status={computeStatus(this.state)}
        isViewCalendarMode={this.isViewCalendarMode}
      />
    );
  }

  /**
   * Render canceled event screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderCanceledEventScreen(): ReactNode {
    if (this.state.response?.result === "success") {
      return <CanceledEvent isViewCalendarMode={this.isViewCalendarMode} />;
    } else {
      return this.renderErrorScreen();
    }
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Render loading screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderSpinner(): ReactNode {
    return this.state.loading ? (
      <Spinner
        containerClassName="slds-p-around_large"
        containerStyle={{
          backgroundColor: "transparent",
          position: this.state.screen === undefined ? "static" : "fixed",
        }}
        variant="brand"
        hasContainer={true}
      />
    ) : (
      <></>
    );
  }

  /**
   * Render the appropriate confirm app screen
   * @returns {React.ReactNode}
   * @private
   */
  private renderScreen(): ReactNode {
    switch (this.state.screen) {
      case ConfirmAppScreen.CANCELED:
        return this.renderCanceledEventScreen();
      case ConfirmAppScreen.CONFIRMED:
        return this.renderConfirmedEventScreen();
      case ConfirmAppScreen.ERROR:
        return this.renderErrorScreen();
      case ConfirmAppScreen.MULTIPLE_RECIPIENTS:
        return this.renderMultipleRecipientScreen();
      case ConfirmAppScreen.START_TIME_UNAVAILABLE:
        return this.renderStartTimesUnavailableScreen();
      case ConfirmAppScreen.VIEW_ALL:
        return this.renderViewAllEventsScreen();
      case ConfirmAppScreen.VIEW_CALENDAR:
        return this.renderViewCalendarWeekScreen();
      case ConfirmAppScreen.CONFIRM_TIME_PROMPT:
        return this.renderConfirmTimePromptScreen();
      default:
        return this.state.loading ? <></> : this.renderErrorScreen();
    }
  }

  /**
   * Render cancel meeting button
   * @returns {React.ReactNode | null}
   * @private
   */
  private renderCancelMeetingButton(): ReactNode | null {
    if (this.state.screen === ConfirmAppScreen.CONFIRMED) {
      return (
        <Button
          id="cancelMeeting"
          key="cancelMeeting"
          label={LocalizationServiceInst.getLabel("cancel_meeting")}
          onClick={() => {
            this.cancel();
          }}
          variant="destructive"
        />
      );
    }
  }

  /**
   * Render close button
   * @returns {React.ReactNode | null}
   * @private
   */
  private renderCloseButton(): ReactNode | null {
    if (!this.state.loading) {
      return (
        <Button
          id="close"
          key="close"
          label={LocalizationServiceInst.getLabel("close")}
          onClick={() => {
            window.close();
          }}
          variant="text-destructive"
        />
      );
    }
  }

  /**
   * Render back button
   * @returns {React.ReactNode | null}
   * @private
   */
  private renderBackButton(): ReactNode | null {
    if (
      !this.state.loading &&
      this.isViewCalendarMode &&
      (this.state.screen === ConfirmAppScreen.CANCELED ||
        this.state.screen === ConfirmAppScreen.START_TIME_UNAVAILABLE)
    ) {
      return (
        <Button
          id="back"
          key="back"
          label={LocalizationServiceInst.getLabel("back")}
          onClick={() => {
            this.viewCalendarWeek();
          }}
        />
      );
    }
  }

  /**
   * Render cancel screen ("back") button
   * @returns {React.ReactNode | null}
   * @private
   */
  private renderCancelScreenButton(): ReactNode | null {
    if (
      this.state.screen === ConfirmAppScreen.MULTIPLE_RECIPIENTS ||
      this.state.screen === ConfirmAppScreen.CONFIRM_TIME_PROMPT
    ) {
      return (
        <Button
          id="cancel"
          key="cancel"
          label={LocalizationServiceInst.getLabel("cancel")}
          onClick={() => {
            if (this.isViewCalendarMode) {
              this.viewCalendarWeek();
            } else {
              this.view();
            }
          }}
        />
      );
    }
  }

  /**
   * Render "Ok" button
   * @returns {React.ReactNode | null}
   * @private
   */
  private renderOkButton(): ReactNode | null {
    if (
      this.state.screen === ConfirmAppScreen.MULTIPLE_RECIPIENTS ||
      this.state.screen === ConfirmAppScreen.CONFIRM_TIME_PROMPT
    ) {
      return (
        <Button
          id="ok"
          key="ok"
          label={
            this.state.screen === ConfirmAppScreen.CONFIRM_TIME_PROMPT
              ? LocalizationServiceInst.getLabel("schedule")
              : LocalizationServiceInst.getLabel("ok")
          }
          variant="brand"
          onClick={() => {
            this.confirm(
              this.startTime,
              this.state.screen === ConfirmAppScreen.MULTIPLE_RECIPIENTS
                ? true
                : false
            );
          }}
        />
      );
    }
  }

  /**
   * Render view calendar header brand
   * @private
   */
  private renderViewCalendarHeaderBrand(): ReactNode | undefined {
    if (this.state.screen === ConfirmAppScreen.VIEW_CALENDAR) {
      return (
        <div
          className={"slds-modal__header mobile_only_hide smallheight_hide"}
          style={{
            backgroundImage:
              "url(/assets/images/themes/oneSalesforce/banner-user-default.png)",
            height: "150px",
            borderBottom: 0,
          }}
        />
      );
    }
  }

  /**
   * Render view calendar header screen
   * @private
   */
  private renderViewCalendarHeader(): ReactNode | undefined {
    if (this.state.screen === ConfirmAppScreen.VIEW_CALENDAR) {
      const {
        availableTimes,
        availableTimesFormatted,
        viewCalendarRange,
      } = this.state.response?.result as ViewCalendarResponse;
      const {
        linkAvailabilityStart,
        linkAvailabilityEnd,
        timeZone,
        language: possibleLanguage,
      } = (this.state.response?.result as BaseConfirmEventResponse)
        .eventRequest as EventRequestResponse;
      const language = LocalizationServiceInst.cleanLocale(possibleLanguage);
      if (
        availableTimes?.length &&
        availableTimesFormatted?.length &&
        viewCalendarRange &&
        linkAvailabilityStart &&
        linkAvailabilityEnd
      ) {
        return (
          <ViewCalendarWeekHeader
            availableTimes={availableTimes}
            availableTimesFormatted={availableTimesFormatted}
            timeZone={timeZone}
            language={language}
            linkAvailabilityStart={linkAvailabilityStart}
            linkAvailabilityEnd={linkAvailabilityEnd}
            viewCalendarRange={viewCalendarRange}
            viewCalendarFunc={this.viewCalendarWeek.bind(this)}
            loading={this.state.loading}
          />
        );
      }
    }
  }

  /**
   * Render modal header
   * @returns {React.ReactNode}
   * @private
   */
  private renderHeader(): ReactNode {
    const allTimesLabel = computeAllTimesLabel(
      this.state,
      this.isViewCalendarMode
    );
    return (
      <div>
        {this.renderViewCalendarHeaderBrand()}
        <div className="slds-text-heading_large slds-p-horizontal_medium slds-p-top_medium slds-p-bottom_xx-small">
          <b>{computeModalHeaderLabel(this.state)}</b>
        </div>
        {allTimesLabel ? (
          <div className="slds-text-title_caps slds-p-horizontal_x-small">
            {allTimesLabel}
          </div>
        ) : null}
        {this.renderViewCalendarHeader()}
      </div>
    );
  }

  /**
   * Render modal footer
   * @private
   */
  private renderFooter(): ReactNode {
    if (
      this.state.loading ||
      this.state.screen === ConfirmAppScreen.VIEW_CALENDAR
    ) {
      return undefined;
    }

    return [
      this.renderBackButton(),
      this.renderCloseButton(),
      this.renderCancelMeetingButton(),
      this.renderCancelScreenButton(),
      this.renderOkButton(),
    ];
  }

  /**
   * Compute modal size
   * @private
   */
  private computeModalSize(): string {
    return this.state.screen === ConfirmAppScreen.VIEW_CALENDAR
      ? "large"
      : "small";
  }

  /**
   * Compute modal padding
   * @private
   */
  private computeModalPadding(): string {
    return this.state.loading &&
      this.state.screen !== ConfirmAppScreen.VIEW_CALENDAR
      ? "slds-p-around_large"
      : "";
  }

  render(): ReactNode {
    return (
      <Modal
        id="confirmAppModal"
        align="center"
        contentClassName={`slds-text-align_center slds-scrollable_y slds-is-relative ${this.computeModalPadding()}`}
        headerClassName="slds-p-around_none"
        disableClose={true}
        footer={this.renderFooter()}
        header={this.renderHeader()}
        isOpen={true}
        size={this.computeModalSize()}
        ariaHideApp={false}
      >
        {this.renderSpinner()}
        {this.renderScreen()}
      </Modal>
    );
  }
}

export default ConfirmApp;
