import { LocalizationServiceInst } from "./localizationService";

/**
 * Interface for interacting with JavaScript's {@link Date} object.
 *
 * Note: Methods here operate mostly on the Date rather than the Time aspect
 */
export interface DateService {
  /**
   * Return a new Date from the provided input
   */
  newDate(): Date;

  /**
   * Return a new Date from the provided input
   * @param {number | string | Date} input
   */
  newDate(input: number | string | Date): Date;

  /**
   * Return a new Date from the provided input
   * @param {number} year
   * @param {number} month
   * @param {number} date
   * @param {number} hours
   * @param {number} minutes
   * @param {number} seconds
   * @param {number} ms
   */
  newDate(
    year: number,
    month: number,
    date?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ): Date;

  /**
   * Return an ISO Date representation from the date and timeZone
   * @param {Date} date
   * @param {string} timeZone
   */
  toIsoDate(date: Date, timeZone?: string): string;

  /**
   * Format the date using the default formatter
   * @param {Date} date
   * @param {string} timeZone
   */
  formatDefault(date: Date, timeZone?: string): string;

  /**
   * Parse a date formatted by the default formatter
   * @param {string} input
   */
  parseDefault(input: string): Date;

  /**
   * Get the start of day for the given date and timeZone
   * @param {Date} date
   * @param {string} timeZone
   */
  getStartOfDay(date: Date, timeZone?: string): Date;

  /**
   * Get the start of day for the given timestamp and timeZone
   * @param {number} timestamp
   * @param {string} timeZone
   */
  getStartOfDay(timestamp: number, timeZone?: string): Date;

  /**
   * Get localized weekend day names for the given locale
   * @param {string} locale
   * @param {string} format
   */
  getWeekDayNames(locale: string, format: string): string[];

  /**
   * Get localized month names for the given locale
   * @param {string} locale
   * @param {string} format
   */
  getMonthNames(locale: string, format: string): string[];
}

type DayMonthFormatType = "short" | "long" | undefined;

class DateServiceClass implements DateService {
  public readonly DEFAULT_TIME_ZONE = "UTC";
  private readonly DEFAULT_LOCALE = LocalizationServiceInst.DEFAULT_LOCALE;

  newDate(): Date;
  newDate(input: number | string | Date): Date;
  newDate(
    year: number,
    month: number,
    date?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ): Date;

  newDate(
    input?: number | string | Date,
    month?: number,
    date?: number,
    hours?: number,
    minutes?: number,
    seconds?: number,
    ms?: number
  ): Date {
    if (typeof input === "number" && typeof month === "number") {
      return new Date(
        input,
        month,
        typeof date === "number" ? date : 1,
        hours || 0,
        minutes || 0,
        seconds || 0,
        ms || 0
      );
    }
    if (typeof input !== "undefined") {
      return new Date(input);
    }
    return new Date();
  }

  toIsoDate(date: Date, timeZone?: string): string {
    const formattedDate = this.parseDefault(this.formatDefault(date, timeZone));
    return formattedDate.toISOString().split("T")[0];
  }

  formatDefault(date: Date, timeZone?: string): string {
    // Default date format in en-US (MM/DD/YYYY) that can be easily parsed later on.
    // This is internal to the service and is primarily used to get formatted Dates in a specific timeZone rather
    // than the host timeZone
    return this.formatDate(date, {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      locale: this.DEFAULT_LOCALE,
      timeZone,
    });
  }

  parseDefault(input: string): Date {
    // Parse date from the default formatter which is always in en-US format (MM/DD/YYYY).
    // See formatDefault() above.
    const dateParts = input.split("/");
    const year = Number(dateParts[2]);
    const month = Number(dateParts[0]) - 1;
    const day = Number(dateParts[1]);
    return this.newDate(year, month, day, 0, 0, 0, 0);
  }

  getStartOfDay(date: Date, timeZone?: string): Date;
  getStartOfDay(timestamp: number, timeZone?: string): Date;

  getStartOfDay(input: Date | number, timeZone?: string): Date {
    // Given a Date (1659585600000 / Wednesday, August 3, 2022 9:00:00 PM GMT-07:00)
    // with timeZone = "America/Los_Angeles" the start of day should be
    // 1659510000000 / Wednesday, August 3, 2022 12:00:00 AM GMT-07:00
    //
    // Given the same Date (1659585600000 / Wednesday, August 3, 2022 9:00:00 PM GMT-07:00)
    // with timeZone = "America/New_York" the start of day should be
    // 1659596400000 / Thursday, August 4, 2022 12:00:00 AM GMT-07:00 since New York is
    // 3 hours ahead of Los Angeles
    //
    // formatDefault() truncates the time part and formats the date in the given timeZone,
    // leaving an easily parseable Date at midnight
    const formattedDateInTimeZone = this.formatDefault(
      this.newDate(input),
      timeZone
    );
    return this.parseDefault(formattedDateInTimeZone);
  }

  getWeekDayNames(locale: string, format: string): string[] {
    const dayNames: string[] = [...Array(7)].map(() => "");
    const dayNums: number[] = [...Array(7)].map((v, i) => i);

    let foundDays = 0;
    const date = this.newDate();

    // Iterate from the current day until we find all the day names
    while (foundDays !== dayNums.length) {
      const day = date.getDay();
      const index = dayNums.indexOf(day);
      dayNames[index] = this.formatDate(date, {
        locale,
        weekday: this.convertDayMonthFormatType(format),
      });
      foundDays += 1;
      date.setDate(date.getDate() + 1);
    }

    return dayNames;
  }

  getMonthNames(locale: string, format: string): string[] {
    // Start from unix epoch, Jan 1 1970 and increment each month until we find each month name
    const date = this.getStartOfDay(this.newDate(0), this.DEFAULT_TIME_ZONE);
    return [...Array(12)].map((value, index) => {
      const monthName = this.formatDate(date, {
        locale,
        month: this.convertDayMonthFormatType(format),
      });
      date.setMonth(index + 1);
      return monthName;
    });
  }

  // Convert string to a Type Literal, to satisfy typescript's string literal type
  private convertDayMonthFormatType(format: string): DayMonthFormatType {
    if (format === "short" || format == "long") {
      return <DayMonthFormatType>format;
    } else {
      return undefined;
    }
  }

  // noinspection JSMethodCanBeStatic
  private formatDate(
    date: Date,
    options: Intl.DateTimeFormatOptions & { locale: string }
  ): string {
    const { locale, ...theRest } = options;
    return date.toLocaleDateString(locale, theRest);
  }
}

export const DateServiceInst = new DateServiceClass();
