import { Alert, Icon } from "@salesforce/design-system-react";
import React from "react";
import { LocalizationServiceInst } from "../services/localizationService";

function IE11Alert(): any {
  return (
    <Alert
      icon={<Icon category="utility" name="error" />}
      labels={{
        heading: LocalizationServiceInst.getLabel("ie11_unsupported_message"),
        headingLink: LocalizationServiceInst.getLabel("more_information"),
      }}
      variant="error"
      onClickHeadingLink={() =>
        window.open(
          "https://help.salesforce.com/s/articleView?id=000386627&type=1"
        )
      }
    />
  );
}

export default IE11Alert;
