/**
 * Options for a HTTP request
 */
export interface RequestOptions {
  method: RequestMethod;
  url: string;
  authorization?: string | null | undefined;
  body?: Record<string, string | null> | null | undefined;
  params?: Map<string, string> | null | undefined;
}

/**
 * Request method
 */
export enum RequestMethod {
  DELETE = "DELETE",
  GET = "GET",
  HEAD = "HEAD",
  PATCH = "PATH",
  POST = "POST",
  PUT = "PUT",
}
