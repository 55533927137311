import {
  FreeTimes,
  FreeTimesFormatted,
  ViewCalendarRange,
} from "../../constants/iqResponses";
import React, { useState } from "react";
import { Button, ButtonGroup } from "@salesforce/design-system-react";
import { LocalizationServiceInst } from "../../services/localizationService";
import { DateServiceInst } from "../../services/dateService";
import { WrappedDatepicker } from "./wrappedDatepicker";

/**
 * Week view calendar header
 * @param props
 * @constructor
 */
export function ViewCalendarWeekHeader(props: {
  availableTimes: FreeTimes[];
  availableTimesFormatted: FreeTimesFormatted[];
  timeZone: string;
  language: string;
  linkAvailabilityStart: number;
  linkAvailabilityEnd: number;
  viewCalendarRange: ViewCalendarRange;
  viewCalendarFunc: (yyyyMmDdDate?: string) => void;
  loading: boolean;
}): any {
  const {
    availableTimes,
    availableTimesFormatted,
    timeZone,
    language,
    linkAvailabilityStart,
    linkAvailabilityEnd,
    viewCalendarRange,
    viewCalendarFunc,
    loading,
  } = props;

  const startOfDayToday = DateServiceInst.getStartOfDay(
    viewCalendarRange.todayStart,
    timeZone
  );
  const [datePickerDate, setDatePickerDate] = useState<Date>(startOfDayToday);

  const colSize = availableTimes.length;

  const previousWeekButtonDisabled =
    loading ||
    !viewCalendarRange.previous ||
    viewCalendarRange.previous.end < linkAvailabilityStart ||
    viewCalendarRange.previous.end < viewCalendarRange.todayStart;
  const nextWeekButtonDisabled =
    loading ||
    !viewCalendarRange.next ||
    viewCalendarRange.next.start > linkAvailabilityEnd;

  const handlePrevious = () => {
    if (previousWeekButtonDisabled) {
      return;
    }

    viewCalendarFunc(viewCalendarRange.previous.isoStart as string);
    const startOfDayPrevious = DateServiceInst.getStartOfDay(
      viewCalendarRange.previous.start,
      timeZone
    );
    setDatePickerDate(
      startOfDayToday.getTime() > startOfDayPrevious.getTime()
        ? startOfDayToday
        : startOfDayPrevious
    );
  };

  const handleNext = () => {
    if (nextWeekButtonDisabled) {
      return;
    }

    viewCalendarFunc(viewCalendarRange.next.isoStart as string);
    setDatePickerDate(
      DateServiceInst.getStartOfDay(viewCalendarRange.next.start, timeZone)
    );
  };

  const handleToday = () => {
    if (loading) {
      return;
    }

    setDatePickerDate(startOfDayToday);
    viewCalendarFunc();
  };

  const formatDatepickerDate = (date: Date) => {
    return DateServiceInst.formatDefault(date);
  };

  const parseDatepickerValue = (str: string) => {
    return DateServiceInst.parseDefault(str);
  };

  const handleDatepickerOnChange = (data) => {
    if (loading) {
      return;
    }

    const startOfDay = DateServiceInst.getStartOfDay(data.date, timeZone);
    if (
      startOfDay.getTime() < linkAvailabilityStart ||
      startOfDay.getTime() > linkAvailabilityEnd
    ) {
      return;
    }

    setDatePickerDate(startOfDay);
    const isoDate = DateServiceInst.toIsoDate(startOfDay, timeZone);

    viewCalendarFunc(isoDate);
  };

  const isDatepickerDateDisabled = (data): boolean => {
    const startOfDayDateTs = DateServiceInst.getStartOfDay(
      data.date,
      timeZone
    ).getTime();

    return (
      startOfDayDateTs < startOfDayToday.getTime() ||
      startOfDayDateTs < linkAvailabilityStart ||
      startOfDayDateTs >= linkAvailabilityEnd
    );
  };

  const todayLabel = LocalizationServiceInst.getLabel("today");
  const nextWeekLabel = LocalizationServiceInst.getLabel("next_week");
  const previousWeekLabel = LocalizationServiceInst.getLabel("previous_week");
  const getDatepickerLabels = () => {
    return {
      abbreviatedWeekDays: DateServiceInst.getWeekDayNames(language, "short"),
      weekDays: DateServiceInst.getWeekDayNames(language, "long"),
      months: DateServiceInst.getMonthNames(language, "long"),
      placeholder: LocalizationServiceInst.getLabel("pick_a_date"),
      today: todayLabel,
    };
  };
  const getDatepickerAssistiveTextLabels = () => {
    return {
      nextMonth: LocalizationServiceInst.getLabel("next_month"),
      openCalendar: LocalizationServiceInst.getLabel("open_calendar"),
      previousMonth: LocalizationServiceInst.getLabel("previous_month"),
      year: LocalizationServiceInst.getLabel("year"),
    };
  };

  const dateRange = `${viewCalendarRange.current.startFormatted} - ${viewCalendarRange.current.endFormatted}`;
  const loadingClassStr = loading ? "loading" : "";
  return (
    <div className="slds-grid slds-grid_vertical slds-p-top_medium viewCalendarWeekHeader">
      <div className="slds-col slds-grid slds-grid_align-spread slds-text-heading_small slds-p-around_medium slds-color__background_gray-3 controlContainer mobile_flex_direction_vertical">
        <div className="slds-col slds-size_1-of-2 slds-text-align_left range">
          <b>{dateRange}</b>
        </div>
        <div className="slds-col slds-size_1-of-2 slds-grid slds-grid_align-end slds-gutters_small">
          <div className="slds-col">
            <ButtonGroup id="viewCalendarControlGroup">
              <Button
                id="viewCalendarPreviousWeek"
                buttonVariant="icon"
                iconCategory="utility"
                iconName="chevronleft"
                iconVariant="border-filled"
                variant="icon"
                title={previousWeekLabel}
                assisitveText={{ icon: previousWeekLabel }}
                disabled={previousWeekButtonDisabled}
                onClick={() => handlePrevious()}
              />
              <Button
                id="viewCalendarNextWeek"
                buttonVariant="icon"
                iconCategory="utility"
                iconName="chevronright"
                iconVariant="border-filled"
                variant="icon"
                title={nextWeekLabel}
                assisitveText={{ icon: nextWeekLabel }}
                disabled={nextWeekButtonDisabled}
                onClick={() => handleNext()}
              />
            </ButtonGroup>
          </div>
          <div className="slds-col">
            <WrappedDatepicker
              id="viewCalendarDatePicker"
              className="slds-text-align_left"
              triggerClassName={`slds-button slds-button_icon-border-filled viewCalendarDatePicker ${loadingClassStr}`}
              onChange={(event, data) => handleDatepickerOnChange(data)}
              dateDisabled={(data) => isDatepickerDateDisabled(data)}
              labels={getDatepickerLabels()}
              assistiveText={getDatepickerAssistiveTextLabels()}
              formatter={(date) => formatDatepickerDate(date)}
              parser={(str) => parseDatepickerValue(str)}
              isIsoWeekday={viewCalendarRange.isoWeekday}
              relativeYearFrom={0}
              relativeYearTo={2}
              value={datePickerDate}
              disabled={loading}
            />
          </div>
          <div className="slds-col">
            <Button
              id="viewCalendarToday"
              variant="outline-brand"
              label={todayLabel}
              assistiveText={{ label: todayLabel }}
              disabled={loading}
              onClick={() => handleToday()}
            />
          </div>
        </div>
      </div>
      <div className="slds-col slds-grid slds-gutters_small slds-p-vertical_small mobile_only_hide">
        {availableTimesFormatted.map((formattedTime, i) => {
          const dayStartTs = availableTimes[i].dayStart;

          const today = dayStartTs === viewCalendarRange.todayStart;
          return (
            <div
              key={formattedTime.dayStart}
              className={`slds-col slds-size_1-of-${colSize}`}
            >
              {today ? (
                <b>{formattedTime.dayStart}</b>
              ) : (
                `${formattedTime.dayStart}`
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
