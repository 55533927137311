import React from "react";
import { ConfirmStatus } from "../../constants/iqResponses";
import { LocalizationServiceInst } from "../../services/localizationService";

/**
 * Confirm app error screen
 * @param {{status: ConfirmStatus}} props
 * @returns {any}
 * @constructor
 */
function ConfirmAppError(props: {
  status: ConfirmStatus;
  isViewCalendarMode: boolean;
}): any {
  const { status, isViewCalendarMode } = props;

  let message;
  switch (status) {
    case ConfirmStatus.START_TIME_UNAVAILABLE:
      message = LocalizationServiceInst.getLabel(
        isViewCalendarMode
          ? "availability_error_view_cal"
          : "availability_error"
      ) as string;
      break;
    case ConfirmStatus.LINK_EXPIRED:
      message = LocalizationServiceInst.getLabel("link_expired") as string;
      break;
    case ConfirmStatus.OTHER_ERROR:
    case ConfirmStatus.REQUEST_INVALID:
    default:
      message = LocalizationServiceInst.getLabel("generic_error") as string;
      break;
  }

  return (
    <div className="slds-p-around_large">
      <p className={"slds-text-color_error"}>{message}</p>
    </div>
  );
}

export default ConfirmAppError;
