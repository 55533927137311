export const INBOX_SERVICES_KEY = "inbox_services";
export const CONFIRM_APP_KEY = "confirm_app";
export const LABEL_KEY_FORMAT: string =
  INBOX_SERVICES_KEY + "." + CONFIRM_APP_KEY + ".{labelName}";

export const FALLBACK_LABELS_MAP: Record<string, string> = {
  "inbox_services.confirm_app.checking_availability": "Checking availability",
  "inbox_services.confirm_app.generic_error":
    "There was an error with your request. Try again later.",
  "inbox_services.confirm_app.your_local_time": "Your Local Time",
  "inbox_services.confirm_app.c": "Select a time - Salesforce Inbox",
  "inbox_services.confirm_app.time_recently_booked":
    "This time has recently been booked.",
  "inbox_services.confirm_app.close": "Close",
  "inbox_services.confirm_app.cancel": "Cancel",
  "inbox_services.confirm_app.ok": "OK",
  "inbox_services.confirm_app.check_your_email":
    "Check your email to review the event invitation.",
  "inbox_services.confirm_app.request_invalid":
    "Not able to find the meeting ...",
  "inbox_services.confirm_app.select_another_time":
    "Select another available time:",
  "inbox_services.confirm_app.event_title": "Event Title: {0}",
  "inbox_services.confirm_app.reservation_canceled": "Reservation Canceled",
  "inbox_services.confirm_app.meeting_time_reserved": "Meeting time reserved!",
  "inbox_services.confirm_app.multiple_recipients_message":
    "This meeting invite has more than one attendee. After you select a time the other recipients can't select a different time. Do you want to continue?",
  "inbox_services.confirm_app.time_already_reserved":
    "A time has already been reserved for this meeting.",
  "inbox_services.confirm_app.cancel_meeting": "Cancel meeting",
  "inbox_services.confirm_app.event_location": "Location: {0}",
  "inbox_services.confirm_app.all_times_in": "All times in: {0}",
  "inbox_services.confirm_app.availability_error":
    "All of the proposed times for this meeting have been filled. Reply to this email to propose available times.",
  "inbox_services.confirm_app.select_meeting_time":
    "Select an available meeting time below:",
  "inbox_services.confirm_app.meeting_already_scheduled":
    "Meeting Already Scheduled",
  "inbox_services.confirm_app.reserve_another_time":
    "To reserve another meeting time, click an available time in the email you received.",
  "inbox_services.confirm_app.next_month": "Next month",
  "inbox_services.confirm_app.open_calendar": "Open Calendar",
  "inbox_services.confirm_app.previous_month": "Previous month",
  "inbox_services.confirm_app.year": "Year",
  "inbox_services.confirm_app.pick_a_date": "Pick a Date",
  "inbox_services.confirm_app.today": "Today",
  "inbox_services.confirm_app.next_week": "Next week",
  "inbox_services.confirm_app.previous_week": "Previous week",
  "inbox_services.confirm_app.link_expired":
    "The meeting link has expired. Reply to this email to propose available times.",
  "inbox_services.confirm_app.back": "Back",
  "inbox_services.confirm_app.reserve_another_time_view_cal":
    "To reserve another meeting time, click Back or an available time in the email you received.",
  "inbox_services.confirm_app.availability_error_view_cal":
    "The selected time for this meeting has been filled. Click Back or reply to this email to propose available times.",
  "inbox_services.confirm_app.ie11_unsupported_message":
    "Extended use of IE11 with Lightning Experience has now ended. As of December 31, 2022, the extended period has ended, and use of Internet Explorer 11 (IE11) with Lightning Experience is no longer supported. Issues with performance or functionality that affect only IE 11 will not be fixed. Please switch to a supported browser.",
  "inbox_services.confirm_app.more_information": "More Information",
  "inbox_services.confirm_app.confirm_time_prompt":
    "Schedule a meeting on {0}?",
  "inbox_services.confirm_app.schedule": "Schedule",
};

export const SUPPORTED_RTL_LANGUAGES: string[] = [
  "ar", // Arabic
  "fa", // Persian
  "he", // Hebrew
  "iw", // Hebrew
  "ji", // Yiddish
  "ur", // Urdu
  "yi", // Yiddish
];
