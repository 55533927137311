import { Datepicker } from "@salesforce/design-system-react";
import React from "react";

/**
 * Wrapper around SLDS React Datepicker
 * @param props
 * @constructor
 */
export function WrappedDatepicker(props: {
  id: string;
  className: string;
  triggerClassName: string;
  onChange: (event, data) => void;
  dateDisabled: (data) => boolean;
  labels: Record<string, any>;
  assistiveText: Record<string, any>;
  formatter: (date) => string;
  parser: (str) => Date;
  isIsoWeekday: boolean;
  relativeYearFrom: number;
  relativeYearTo: number;
  value: Date;
  disabled: boolean;
}): any {
  return <Datepicker {...props} />;
}
