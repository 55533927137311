// Manually import these polyfills in our entrypoint file
// These are required for IE11 compatibility
import "core-js/features/url-search-params";
import "whatwg-fetch";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/app";
import { IconSettings } from "@salesforce/design-system-react";

/**
 * Main application screen
 */
ReactDOM.render(
  <React.StrictMode>
    <IconSettings iconPath="/_slds/icons">
      <App />
    </IconSettings>
  </React.StrictMode>,
  document.getElementById("root")
);
