import React from "react";
import { FreeTimes, FreeTimesFormatted } from "../../constants/iqResponses";
import { Button } from "@salesforce/design-system-react";

/**
 * Week view calendar component
 * @param props
 * @constructor
 */
function ViewCalendarWeek(props: {
  availableTimes: FreeTimes[];
  availableTimesFormatted: FreeTimesFormatted[];
  confirmEvent: (startTime, isPrimaryAttendee) => void;
  duration: number;
  loading: boolean;
}): any {
  const {
    availableTimes,
    availableTimesFormatted,
    confirmEvent,
    loading,
  } = props;

  const colSize = availableTimes.length;

  const handleConfirm = (startTime: number) => {
    if (loading) {
      return;
    }
    confirmEvent(startTime, false);
  };

  return (
    <div className="slds-grid slds-grid_vertical viewCalendarWeek">
      <div className="slds-col slds-grid slds-gutters_small mobile_flex_direction_vertical">
        {availableTimesFormatted.map((formattedTime, i) => {
          const formattedDayStart = formattedTime.dayStart;
          const isPast = availableTimes[i].past;

          const pastClassStr = isPast ? "slds-color__background_gray-2" : "";
          return (
            <div
              key={formattedDayStart}
              className={`slds-col slds-size_1-of-${colSize} slds-p-vertical_xx-small ${pastClassStr}`}
            >
              <div className="slds-col slds-p-vertical_xx-small mobile_only_visible">
                {`${formattedDayStart}`}
              </div>
              {isPast
                ? undefined
                : formattedTime.startTimes?.map((formattedStartTime, j) => {
                    const startTime = availableTimes[i].startTimes?.[j];
                    return (
                      <div
                        key={startTime}
                        className="slds-col slds-p-vertical_xx-small"
                      >
                        <Button
                          id={`${startTime}`}
                          className="slds-button_stretch"
                          disabled={loading}
                          onClick={() => handleConfirm(startTime as number)}
                        >
                          <span className="slds-assistive-text">
                            {formattedDayStart}
                          </span>
                          {formattedStartTime}
                        </Button>
                      </div>
                    );
                  })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ViewCalendarWeek;
