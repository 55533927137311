export const IQ_BASE_PATH = "/svc/v1";
export const IQ_URLS = {
  PROD: "https://app.salesforceiq.com",
  PROD_F: "https://app-frankfurt.salesforceiq.com",
  RC: "https://app-rc.salesforceiq.com",
  STAGING: "https://app-staging.salesforceiq.com",
  DEV_FE: "http://127.0.0.1:8080",
  DEV_BE: "http://127.0.0.1:8888",
  FALCON_DEV: "https://apiq-apiv1-c01.apiq.sfdc-i6sgwq.svc.sfdcfc.net",
  FALCON_TEST: "https://apiq-apiv1-c01.apiq.sfdc-ckzqgc.svc.sfdcfc.net",
  FALCON_PERF: "https://apiq-apiv1-c02.apiq.sfdc-ckzqgc.svc.sfdcfc.net",
  FALCON_STAGE: "https://apiq-apiv1-c01.apiq.sfdc-shbmgi.svc.sfdcfc.net",
  FALCON_PROD_EU: "https://apiq-apiv1-c01.apiq.sfdc-yzvdd4.svc.sfdcfc.net",
  FALCON_PROD_USA: "https://apiq-apiv1-c01.apiq.sfdc-lywfpd.svc.sfdcfc.net",
};

export const IQ_URLS_ARR = Object.values(IQ_URLS);

export function isAllowedUrl(url: string): boolean {
  return IQ_URLS_ARR.indexOf(url) >= 0;
}

export const IQ_FEATURE_PATHS = {
  DYNAMIC_CAL: "/dynamiccal",
};

export const IQ_DYNAMIC_CAL_PATHS = {
  CANCEL_EVENT_REQUEST: "/cancelEventRequest",
  SELECT_EVENT_TIME: "/selectEventTime",
  VIEW_REQUEST: "/viewRequest",
  VIEW_CALENDAR_WEEK: "/viewCalendarWeek",
};
