import { RequestServiceInst } from "./requestService";
import {
  IQ_BASE_PATH,
  IQ_DYNAMIC_CAL_PATHS,
  IQ_FEATURE_PATHS,
  IQ_URLS,
} from "../../shared/iqUrls";
import {
  RequestMethod,
  RequestOptions,
} from "../constants/requestServiceConstants";
import { ConfirmAppResponse } from "../constants/iqResponses";

/**
 * Calls "viewRequest" endpoint
 * @param {string} encryptedId
 * @param {string} url
 * @returns {Promise<ConfirmAppResponse>}
 */
export async function viewEvents(
  encryptedId: string,
  url: string = IQ_URLS.PROD
): Promise<ConfirmAppResponse> {
  const params = new Map<string, any>();
  params.set("encryptedId", encryptedId);
  const requestOptions: RequestOptions = {
    method: RequestMethod.POST,
    url: `${url}${IQ_BASE_PATH}${IQ_FEATURE_PATHS.DYNAMIC_CAL}${IQ_DYNAMIC_CAL_PATHS.VIEW_REQUEST}`,
    params,
  };

  return RequestServiceInst.makeRequest<ConfirmAppResponse>(requestOptions);
}

/**
 * Calls "viewCalendarWeek" endpoint
 * @param {string} encryptedId
 * @param {string} isoDate
 * @param {string} url
 * @returns {Promise<ConfirmAppResponse>}
 */
export async function viewCalendarWeek(
  encryptedId: string,
  isoDate?: string,
  url: string = IQ_URLS.PROD
): Promise<ConfirmAppResponse> {
  const params = new Map<string, any>();
  params.set("encryptedId", encryptedId);
  if (isoDate) {
    params.set("isoDate", isoDate);
  }
  const requestOptions: RequestOptions = {
    method: RequestMethod.POST,
    url: `${url}${IQ_BASE_PATH}${IQ_FEATURE_PATHS.DYNAMIC_CAL}${IQ_DYNAMIC_CAL_PATHS.VIEW_CALENDAR_WEEK}`,
    params,
  };

  return RequestServiceInst.makeRequest<ConfirmAppResponse>(requestOptions);
}

/**
 * Calls "selectEventTime" endpoint
 * @param {string} encryptedId
 * @param {string} startTime
 * @param {boolean} isPrimaryAttendee
 * @param {string} url
 * @returns {Promise<ConfirmAppResponse>}
 */
export async function confirmEvent(
  encryptedId: string,
  startTime: string,
  isPrimaryAttendee: boolean,
  url: string = IQ_URLS.PROD
): Promise<ConfirmAppResponse> {
  const params = new Map<string, any>();
  params.set("encryptedId", encryptedId);
  params.set("start", startTime);
  params.set("isPrimaryAttendee", isPrimaryAttendee);
  const requestOptions: RequestOptions = {
    method: RequestMethod.POST,
    url: `${url}${IQ_BASE_PATH}${IQ_FEATURE_PATHS.DYNAMIC_CAL}${IQ_DYNAMIC_CAL_PATHS.SELECT_EVENT_TIME}`,
    params,
  };

  return RequestServiceInst.makeRequest<ConfirmAppResponse>(requestOptions);
}

/**
 * Calls "cancelEventRequest" endpoint
 * @param {string} encryptedId
 * @param {string} url
 * @returns {Promise<ConfirmAppResponse>}
 */
export async function cancelEvent(
  encryptedId: string,
  url: string = IQ_URLS.PROD
): Promise<ConfirmAppResponse> {
  const params = new Map<string, any>();
  params.set("encryptedId", encryptedId);
  const requestOptions: RequestOptions = {
    method: RequestMethod.POST,
    url: `${url}${IQ_BASE_PATH}${IQ_FEATURE_PATHS.DYNAMIC_CAL}${IQ_DYNAMIC_CAL_PATHS.CANCEL_EVENT_REQUEST}`,
    params,
  };

  return RequestServiceInst.makeRequest<ConfirmAppResponse>(requestOptions);
}
