import React from "react";
import { ConfirmStatus } from "../../constants/iqResponses";
import { LocalizationServiceInst } from "../../services/localizationService";
import { getStartEndDisplayTimeZone } from "../../utils/legacyDateTimeUtils";

/**
 * Confirmed event screen
 * @param {{duration: number, location: string, offsetInMin: number, start: number, startEndTimeFormatted: string, status: ConfirmStatus, timeZoneName: string}} props
 * @returns {any}
 * @constructor
 */
function ConfirmedEvent(props: {
  duration: number;
  location: string;
  offsetInMin: number;
  start: number;
  startEndTimeFormatted: string;
  status: ConfirmStatus;
  timeZoneName: string;
}): any {
  const {
    duration,
    location,
    offsetInMin,
    start,
    startEndTimeFormatted,
    status,
    timeZoneName,
  } = props;

  let headingText, subHeadingText;
  switch (status) {
    case ConfirmStatus.SUCCESS:
      headingText = LocalizationServiceInst.getLabel("meeting_time_reserved");
      subHeadingText = LocalizationServiceInst.getLabel("check_your_email");
      break;
    case ConfirmStatus.ALREADY_CONFIRMED:
      headingText = LocalizationServiceInst.getLabel(
        "meeting_already_scheduled"
      );
      subHeadingText = LocalizationServiceInst.getLabel(
        "time_already_reserved"
      );
      break;
  }

  return (
    <div className="slds-p-around_large">
      <p className={"slds-text-color_success slds-p-bottom_small"}>
        <b>{headingText}</b>
      </p>
      <p className={"slds-p-bottom_small"}>{subHeadingText}</p>
      {location ? (
        <p>{LocalizationServiceInst.getLabel("event_location", [location])}</p>
      ) : null}
      <p>
        {startEndTimeFormatted
          ? startEndTimeFormatted
          : getStartEndDisplayTimeZone(
              start,
              start + duration,
              timeZoneName,
              offsetInMin
            )}
      </p>
    </div>
  );
}

export default ConfirmedEvent;
