import {
  RequestMethod,
  RequestOptions,
} from "../constants/requestServiceConstants";

/**
 * Interface to make HTTP requests
 */
interface RequestService {
  /**
   * Make an HTTP request with the given options
   * @param {RequestOptions} options
   * @returns {Promise<TResult>}
   */
  makeRequest<TResult>(options: RequestOptions): Promise<TResult>;
}

/**
 * Implementation of {@link RequestService} that uses fetch to make HTTP requests
 */
class FetchRequestServiceClass implements RequestService {
  public async makeRequest<TResult>(options: RequestOptions): Promise<TResult> {
    if (
      (options.method == RequestMethod.GET ||
        options.method == RequestMethod.HEAD) &&
      options.body
    ) {
      throw new Error(`Cannot include a body for ${options.method}`);
    }

    let url = options.url;
    if (options.params) {
      const params = new URLSearchParams();
      options.params.forEach((value, key): void => {
        params.append(key, value);
      });
      url = `${url}?${params.toString()}`;
    }

    // eslint-disable-next-line no-console
    console.log(`Calling ${options.url}`);

    return fetch(url, {
      method: options.method.toString(),
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        ...(options.authorization
          ? { Authorization: `Bearer ${options.authorization}` }
          : {}),
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      ...(options.body ? { body: JSON.stringify(options.body) } : {}),
    }).then(
      (resp): Promise<TResult> => {
        // eslint-disable-next-line no-console
        console.log(`Received a response from ${options.url}`);
        return resp.json();
      }
    );
  }
}

export const RequestServiceInst = new FetchRequestServiceClass();
