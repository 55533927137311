import "core-js/features/url-search-params";

/**
 * Utility for URL related methods
 */
export default class UrlUtils {
  /**
   * Get the current query params as {@link URLSearchParams}
   * @returns {Map<string, string>}
   */
  public static getQueryParams(): URLSearchParams {
    return new URLSearchParams(window.location.search);
  }
}
