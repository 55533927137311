import React from "react";
import { Button } from "@salesforce/design-system-react";
import { getStartEndDisplay } from "../../utils/legacyDateTimeUtils";

/**
 * View all event times screen
 * @param props {{availableTimes: number[], availableTimesFormatted: string[], confirmEvent: (startTime, isPrimaryAttendee) => void, duration: number, message: string, offsetInMin: number, timeZoneName: string, warning?: string}}
 * @returns {any}
 * @constructor
 */
function ViewEvents(props: {
  availableTimes: number[];
  availableTimesFormatted: string[];
  confirmEvent: (startTime, isPrimaryAttendee) => void;
  duration: number;
  message: string;
  offsetInMin: number;
  timeZoneName: string;
  warning?: string;
}): any {
  const {
    availableTimes,
    availableTimesFormatted,
    confirmEvent,
    duration,
    message,
    offsetInMin,
    timeZoneName,
    warning,
  } = props;

  return (
    <div className="slds-p-around_large">
      {warning ? (
        <p className={"slds-text-color_destructive slds-p-bottom_small"}>
          <b>{warning}</b>
        </p>
      ) : null}
      <p>{message}</p>
      <div className={"slds-grid slds-grid_vertical"}>
        {availableTimes.map((value, index) => {
          return (
            <div key={index} className={"slds-col slds-p-around_small"}>
              <Button
                id={`${value}`}
                variant="outline-brand"
                label={
                  availableTimesFormatted
                    ? availableTimesFormatted[index]
                    : getStartEndDisplay(
                        value,
                        value + duration,
                        timeZoneName,
                        offsetInMin
                      )
                }
                onClick={() => confirmEvent(value, false)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ViewEvents;
