export interface BaseResponse {
  message: string | null;
  statusCode: number;
  success: boolean;
}

export enum ConfirmStatus {
  SUCCESS = "SUCCESS",
  START_TIME_UNAVAILABLE = "START_TIME_UNAVAILABLE",
  REQUEST_INVALID = "REQUEST_INVALID",
  ALREADY_CONFIRMED = "ALREADY_CONFIRMED",
  MULTIPLE_RECIPIENTS = "MULTIPLE_RECIPIENTS",
  OTHER_ERROR = "OTHER_ERROR",
  LINK_EXPIRED = "LINK_EXPIRED",
}

export interface EventRequestResponse {
  attendees: string[];
  calendarId: string;
  conferenceCallDataSourceIdentifier: string | null;
  description: string;
  duration: number;
  eventTitle: string;
  id: string;
  insertConferenceCall: boolean;
  language: string | null;
  locale: string | null;
  location: string | null;
  offsetInMin: number;
  primaryAttendee: string | null;
  recipients: string[];
  searchOrdinal: number;
  startTimes: number[];
  timeZone: string;
  timeZoneName: string;
  userEmail: string;
  linkAvailabilityStart: number | null;
  linkAvailabilityEnd: number | null;
}

export interface ScheduledEvent {
  description: string;
  end: number;
  id: string | null;
  location: string;
  participants: { name: string | null; email: string }[];
  searchOrdinal: number;
  start: number;
  startEndTimeFormatted: string;
  title: string;
  url: string | null;
}

export interface TimeRange {
  start: number;
  end: number;
  startFormatted: string | null;
  endFormatted: string | null;
  isoStart: string | null;
  isoEnd: string | null;
}

export interface ViewCalendarRange {
  current: TimeRange;
  previous: TimeRange;
  next: TimeRange;
  todayStart: number;
  isoWeekday: boolean;
}

export interface FreeTimes {
  dayStart: number;
  startTimes: number[] | null;
  weekend: boolean;
  past: boolean;
}

export interface FreeTimesFormatted {
  dayStart: string;
  startTimes: string[] | null;
}

export interface ViewRequestResponse extends BaseConfirmEventResponse {
  availableTimes: number[] | null;
  availableTimesFormatted: string[] | null;
}

export interface ViewCalendarResponse extends BaseConfirmEventResponse {
  availableTimes: FreeTimes[] | null;
  availableTimesFormatted: FreeTimesFormatted[] | null;
  viewCalendarRange: ViewCalendarRange | null;
}

export interface BaseConfirmEventResponse {
  eventRequest: EventRequestResponse | null;
  id: string | null;
  labelsMap: Record<string, string> | null;
  scheduledEvent: ScheduledEvent | null;
  searchOrdinal: number;
  status: ConfirmStatus;
}

export interface ConfirmAppResponse extends BaseResponse {
  result: BaseConfirmEventResponse | string;
}
