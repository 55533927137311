import {
  FALLBACK_LABELS_MAP,
  LABEL_KEY_FORMAT,
  SUPPORTED_RTL_LANGUAGES,
} from "../constants/localizationConstants";

/**
 * Interface for localization service
 */
export interface LocalizationService {
  /**
   * Store localized labels
   * @param {Record<string, string>} labelsMap
   */
  storeLocalizedLabels(labelsMap: Record<string, string>): void;

  /**
   * Get a localized label
   * @param {string} labelName
   * @param {string[]} substitutions
   * @returns {string | undefined}
   */
  getLabel(labelName: string, substitutions: string[]): string | undefined;

  /**
   * Determine if the current language is RTL or not
   * @param {string} language
   * @returns {boolean}
   */
  isRtlLanguage(language: string): boolean;

  /**
   * Return a clean locale
   * @param {string} locale
   */
  cleanLocale(locale: string): string;
}

/**
 * Implementation of {@link LocalizationService}
 */
class LocalizationServiceClass implements LocalizationService {
  public readonly DEFAULT_LOCALE = "en-US";

  LOCALIZED_LABELS_MAP: Record<string, string> = {};

  public storeLocalizedLabels(labelsMap: Record<string, string>): void {
    labelsMap = labelsMap || {};
    Object.keys(labelsMap).forEach((key) => {
      if (!this.LOCALIZED_LABELS_MAP[key]) {
        this.LOCALIZED_LABELS_MAP[key] = labelsMap[key];
      }
    });
  }

  public getLabel(
    labelName: string,
    substitutions: string[] = []
  ): string | undefined {
    const key = LABEL_KEY_FORMAT.replace("{labelName}", labelName);
    let label = this.LOCALIZED_LABELS_MAP[key] || FALLBACK_LABELS_MAP[key];
    if (label) {
      for (let i = 0; i < substitutions.length; i++) {
        label = label.replace(
          new RegExp("\\{" + i + "}", "g"),
          substitutions[i]
        );
      }
      return label;
    }
    return labelName;
  }

  public isRtlLanguage(language: string | null | undefined): boolean {
    if (!language) {
      return false;
    }
    try {
      const languageCode = (this.splitLocale(language) as string[])[0];
      return SUPPORTED_RTL_LANGUAGES.indexOf(languageCode) !== -1;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(
        "Could not find a valid language code. Defaulting layout to LTR",
        e
      );
      return false;
    }
  }

  public cleanLocale(locale: string | null | undefined): string {
    const localeParts = this.splitLocale(locale);
    if (localeParts?.length) {
      return (
        localeParts[0] + (localeParts.length > 1 ? `-${localeParts[1]}` : "")
      );
    }
    return this.DEFAULT_LOCALE;
  }

  // noinspection JSMethodCanBeStatic
  private splitLocale(locale: string | null | undefined): string[] | undefined {
    if (!locale) {
      return undefined;
    }
    return (locale || "").split(/[_-]/);
  }
}

export const LocalizationServiceInst = new LocalizationServiceClass();
