import { LocalizationServiceInst } from "../services/localizationService";

/**
 * Imported directly from https://github.com/relateiq/mailapp-ift/blob/staging/chrome/js/confirm-app/confirm-app.js
 *
 * NOTE: This is used as a fallback when formatted times are not returned by the backend. Starting 224, formatted
 * start/end times are always returned, but leaving this here just in-case we decide to change that.
 */

export const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];

function getDateDisplay(date: Date): string {
  return date.getUTCMonth() + 1 + "/" + date.getUTCDate();
}

function getTimeDisplay(date: Date): string {
  let hour = date.getUTCHours();
  const ampm = hour < 12 ? "AM" : "PM";
  hour = hour <= 12 ? hour : hour - 12;
  hour = hour === 0 ? 12 : hour;
  const minute = date.getUTCMinutes();
  const minuteStr = minute < 10 ? "0" + minute : minute;
  return hour + ":" + minuteStr + " " + ampm;
}

export function getStartEndDisplay(
  start: number,
  end: number,
  timeZoneName: string,
  offsetInMin: number
): string {
  if (!(timeZoneName && offsetInMin)) {
    // if timezone argument null, default to browser's local time
    // getTimezoneOffset() gives the negative offset. e.g. for America/New York, it would give 240. We will negate it
    // need to pass start as argument to get offset, because that will get offset for current time (works for daylight savings)
    offsetInMin = new Date(start).getTimezoneOffset() * -1;
  }
  const offsetInMs = offsetInMin * 60 * 1000;

  // apply the timezone offset
  const startDate = new Date(start + offsetInMs);
  const endDate = new Date(end + offsetInMs);

  const startDateStr = getDateDisplay(startDate);
  const startFullDateStr =
    WEEK_DAYS[startDate.getUTCDay()] +
    " " +
    startDateStr +
    ", " +
    getTimeDisplay(startDate);
  const endDateStr = getDateDisplay(endDate);
  const endTimeStr = getTimeDisplay(endDate);
  // if end date different from start date, prepend with date
  const endFullDateStr =
    startDateStr !== endDateStr ? endDateStr + ", " + endTimeStr : endTimeStr;
  return startFullDateStr + " - " + endFullDateStr;
}

export function getStartEndDisplayTimeZone(
  start: number,
  end: number,
  timeZoneName: string,
  offsetInMin: number
): string {
  const startEndDisplay = getStartEndDisplay(
    start,
    end,
    timeZoneName,
    offsetInMin
  );
  if (!(timeZoneName && offsetInMin)) {
    // if timezone argument null, default to browser's local time
    timeZoneName = LocalizationServiceInst.getLabel(
      "your_local_time"
    ) as string;
  }
  return startEndDisplay + "(" + timeZoneName + ")";
}
